import { Route, Routes, useNavigate } from 'react-router-dom';
import { AppAppBar } from '../../components';
import { ROUTES } from '../../utils/constants';
import { DevRoute } from '../../utils/helper';

type Props = {}

const MainLayout = (props: Props) => {
    return (
        <div>
            <AppAppBar />
            <Routes>
                <Route
                    key={ROUTES.DASHBOARD}
                    path={ROUTES.DASHBOARD}
                    element={<DevRoute title='Dashboard' />}
                />
                <Route
                    key={ROUTES.SHOWS}
                    path={ROUTES.SHOWS}
                    element={<DevRoute title={ROUTES.SHOWS} />}
                />
                <Route
                    key={ROUTES.Live}
                    path={ROUTES.Live}
                    element={<DevRoute title={ROUTES.Live} />}
                />
                <Route
                    key={ROUTES.FAVORITES}
                    path={ROUTES.FAVORITES}
                    element={<DevRoute title={ROUTES.FAVORITES} />}
                />
            </Routes>
        </div>
    )
}

export default MainLayout

