export const COLORS = {
    primary: '#ff516b',
    secondary: '#ff253a',
    title: '#333',
    subTitle: '#666',
    white: '#ffffff',
    black: '#000000',
    whiteOpaque: '#fff7',
    danger: '#f5234a',
    gray8: '#888',
    grayC: '#ccc',
    grayAC: '#acacac',
    suceess: '#4eda92',
    fogWhite: '#e9ecef',
    textDark: '#171b20',
    inputBg: '#eceff0',
    inputColor: '#495057',
    inputBorder: '#dcdfdf',
    borderFocus: '#80bdff',
    info: '#17a2b8',
}

export const ROUTES = {
    DASHBOARD: '/*',
    SHOWS: 'shows',
    ANNOUNCE: 'announce',
    FAVORITES: 'favorites',
    Live: 'live',
}

// export const MEMBERSTACK_CONFIG = { publicKey: "pk_e94c57b50e2a1593d4ad" }
export const MEMBERSTACK_CONFIG = { publicKey: "pk_sb_6f464d3430c4a4927e79" }
