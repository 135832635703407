/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { IAuth } from "../types";


export const getToken = (): IAuth => JSON.parse(localStorage.getItem('user') || '{}');
export const setToken = (user: IAuth) => localStorage.setItem('user', JSON.stringify(user));
export const deleteToken = () => localStorage.removeItem('user');

// eslint-disable-next-line consistent-return
export const getID = () => {
    const token: IAuth = getToken();
    if (Object.keys(token).length !== 0) {
        const id = token?.user?.id;
        return id;
    }
    return null;
};
