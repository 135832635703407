import { Box, type SxProps, type Theme, Typography } from '@mui/material';
import AuthLogin from './auth-forms/AuthLogin';
import { MainCard } from '../../components';
import logo from '../../asstes/Logo.png'

const Login = () => {
    return (
        <MainCard>
            <Box sx={loginWrapper}>
                <img
                    src={logo}
                    alt='Logo'
                    loading="lazy"
                    width={193}
                    height={193}
                />
                <Typography sx={logoText}>LOGIN</Typography>
                <AuthLogin />
            </Box>
        </MainCard>
    );
}

export default Login;

const logoText: SxProps<Theme> | undefined = {
    textAlign: 'center',
    fontSize: 28,
    fontWeight: '700',
    marginBottom: '15px',
}
const loginWrapper: SxProps<Theme> | undefined = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}