import { useMemberstack } from '@memberstack/react';
import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppAppBar } from './components';
import { AuthLayout, MainLayout } from './pages';
import Login from './pages/authLayout/Login';
import Signup from './pages/authLayout/Signup';
import { setToken, useCommonStyles, UserContext } from './utils';

function App() {
  const { userState, setUserState } = useContext(UserContext); //can be replaced with memberStack useAuth

  const classes = useCommonStyles()


  return (
    <div className={classes.root}>
      {/* <AppAppBar /> */}
      <Routes>
        <Route
          key='root'
          path="*"
          element={userState?.id?.length ? <MainLayout /> : <AuthLayout />}
        />
      </Routes>
    </div>
  );
}

export default App;
