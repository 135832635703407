import { Box, type SxProps, type Theme } from '@mui/material';
import { Route, Routes } from 'react-router-dom'
import Login from './Login';
import Signup from './Signup';
import logo from '../../asstes/Logo.png'

const AuthLayout = () => {

    return (
        // <Box>
        //     <Box sx={background} />
        <Box sx={body}>
            <Routes>
                <Route key="login" path="/*" element={<Login />} />
                <Route key="signup" path="signup" element={<Signup />} />
            </Routes>
        </Box >
        // </Box>
    )
}

export default AuthLayout

const background: SxProps<Theme> | undefined = {
    backgroundColor: 'black',
    position: 'absolute',
    transform: "rotate(25deg)",
    width: '200vw',
    height: '200vh',
    backgroundImage: `url(${logo})`,
    filter: 'blur(3px)',
    zIndex: -100,
    opacity: 0.5,
}

const body: SxProps<Theme> | undefined = {
    backgroundColor: '#050505cc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
        content: '""',
        backgroundImage: `url(${logo})`,
        backgroundColor: 'black',
        filter: 'blur(3px)',
        zIndex: -10,
        opacity: 0.5,
        transform: "rotate(25deg)",
        width: '200%',
        height: '200%',
        position: 'absolute',
        top: '-50%',
        left: '-50%',
    }
}
