import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useCommonStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  formWrapper: {
    display: 'flex',
    margin: '0 -15px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
    },
  },
  inputWrapper: {
    flex: 1,
    display: 'flex',
    padding: '0 15px',
    margin: '3rem -15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  selectOption: {
    fontSize: '18px !important',
    fontWeight: 700,
    lineHeight: '22px'
  },
  gridContent: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  }
}));

export default useCommonStyles;