import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "@fontsource/inria-sans";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { theme, UserContextProvider } from './utils';
import { MemberstackProvider } from '@memberstack/react';
import { MEMBERSTACK_CONFIG } from './utils/constants';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserContextProvider>
        <MemberstackProvider config={MEMBERSTACK_CONFIG}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </MemberstackProvider>
      </UserContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
