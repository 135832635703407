import { type ReactNode } from 'react';
import {
    Box,
    Card,
    CardContent,
    // useTheme,
    type SxProps,
    type Theme,
} from '@mui/material';

type Props = {
    children: ReactNode
}

const MainCard = ({ children, ...other }: Props) => {
    // const theme = useTheme();

    return (

        <Card
            elevation={3}
            sx={cardBaseStyles}
            {...other}
        >
            <CardContent>
                <Box sx={{ p: { xs: 1, sm: 1.5, md: 2, xl: 2.5 } }}>{children}</Box>
            </CardContent>
        </Card>

    )
};

export default MainCard;

const cardBaseStyles: SxProps<Theme> | undefined = {
    boxShadow: '10px 10px #FF3F34',
    border: '1px solid #FF3F34',
    maxWidth: { xs: 475, lg: 600 },
    margin: { xs: 2.5, md: 3 },
    '& > *': {
        flexGrow: 1,
        flexBasis: '50%'
    }
}