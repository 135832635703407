import {
    Button,
    type SxProps,
    type Theme
} from '@mui/material'
import { type MouseEventHandler } from 'react';

type Props = {
    title: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    sx?: SxProps<Theme> | undefined
    onClick?: MouseEventHandler<HTMLAnchorElement> | undefined
    href?: string //if possible ignore href as it reload the app
}

export const PrimaryButton = (props: Props) => {
    const { disabled, type, sx, title, onClick, href } = props
    return (
        <Button
            disabled={disabled}
            fullWidth
            size="large"
            type={type}
            variant="contained"
            sx={sx}
            href={href?.length ? href : ''}
            onClick={onClick}
        >
            {title}
        </Button>
    )
}

export const SecondaryButton = (props: Props) => {
    const { disabled, type, sx, title, onClick, href } = props
    return (
        <Button
            disabled={disabled}
            fullWidth
            size="large"
            type={type}
            variant="outlined"
            href={href?.length ? href : ''}
            onClick={onClick}
            sx={sx}
        >
            {title}
        </Button>
    )
}

// const base: SxProps<Theme> | undefined = {
//     backgroundColor: green[500],
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     '&:hover': { backgroundColor: green[700] },
// }