
import { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ROUTES } from '../utils/constants';
import { deleteToken, UserContext } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useMemberstack } from '@memberstack/react';

interface Props {
}

const drawerWidth = 240;

const authenticatedNavItems = [
    { title: 'Home', route: 'Home' },
    { title: 'Live', route: ROUTES.Live },
    { title: 'Shows', route: ROUTES.SHOWS }
];

const nonAuthenticatedNavItems = [
    { title: 'Login', route: 'login' },
    { title: 'Signup', route: 'signup' },
    { title: 'About us', route: 'aboutUs' }
];

export default function DrawerAppBar(props: Props) {
    //   const {  } = props;
    const { userState, setUserState } = useContext(UserContext);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [navItems, setNavItems] = useState(nonAuthenticatedNavItems)
    const navigate = useNavigate()
    const memberstack = useMemberstack();


    useEffect(() => {
        if (userState?.id?.length) {
            setNavItems(authenticatedNavItems)
        } else {
            setNavItems(nonAuthenticatedNavItems)

        }
    }, [userState])


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2, }}>
                Dream Stream
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.title} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                console.log(item.title, 'clicked')
                                navigate(item.route)
                            }}
                            sx={{ textAlign: 'center' }}
                        >
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
                {userState?.id?.length &&
                    < ListItem key={'logout'} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                //@ts-ignore
                                setUserState(null)
                                deleteToken();
                                memberstack.logout();
                            }}
                            sx={{ textAlign: 'center' }}
                        >
                            <ListItemText primary={' Logout'} />
                        </ListItemButton>
                    </ListItem>
                }
            </List>
        </Box >
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav" variant='elevation' sx={{ display: 'flex', alignItems: 'center', paddingX: 6 }}>
                <Toolbar sx={{ width: '100%', maxWidth: 1400 }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: '#efefef' }}
                    >
                        Dream Stream
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button
                                key={item.title}
                                onClick={() => {
                                    console.log(item.title, 'clicked')
                                    navigate(item.route)
                                }}
                                sx={{ color: '#fff' }}
                            >
                                {item.title}
                            </Button>
                        ))}
                        {
                            userState?.id?.length &&
                            <Button
                                variant='contained'
                                key={'logout'}
                                onClick={() => {
                                    memberstack.logout();
                                    //@ts-ignore
                                    setUserState(null)
                                    deleteToken();
                                }}
                                sx={{ color: '#fff', marginLeft: 4, backgroundColor: '#aa2222' }}
                            >
                                {'Logout'}
                            </Button>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}
